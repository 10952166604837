var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-page-settings" },
    [
      _c("Hero", [
        _c("div", { staticClass: "hero-inner" }, [
          _vm.selectedEvent
            ? _c("div", { staticClass: "hero-inner__text" }, [
                _vm._v(" " + _vm._s(_vm.selectedEvent.title) + " ")
              ])
            : _vm._e()
        ])
      ]),
      _c("v-container", { staticClass: "profile-container" }, [
        _c(
          "div",
          { staticClass: "profile" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                staticClass: "profile__form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.save($event)
                  }
                }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "3" } },
                      [
                        _c("ImageUploader", {
                          attrs: {
                            value: _vm.user.avatar.url || _vm.avatarPlug,
                            initials: _vm.initials,
                            rules: [_vm.fileSizeRule]
                          },
                          on: { change: _vm.onAvatarChange }
                        })
                      ],
                      1
                    ),
                    _c("v-col", { attrs: { cols: "12", lg: "9" } }, [
                      _c("div", { staticClass: "profile__title" }, [
                        _vm._v(" " + _vm._s(_vm.$t("profile settings")) + " ")
                      ]),
                      _c("div", { staticClass: "profile__form" }, [
                        _c(
                          "div",
                          { staticClass: "profile__input" },
                          [
                            _c("v-text-field", {
                              staticClass: "text-field",
                              attrs: {
                                name: "lname",
                                solo: "",
                                rules: [_vm.requiredRule, _vm.minLengthRule],
                                label: _vm.upperFirst(_vm.$t("last name")),
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.user.lastName,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "lastName", $$v)
                                },
                                expression: "user.lastName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "profile__input" },
                          [
                            _c("v-text-field", {
                              staticClass: "text-field",
                              attrs: {
                                rules: [_vm.requiredRule, _vm.minLengthRule],
                                solo: "",
                                label: _vm.upperFirst(_vm.$t("first name")),
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.user.firstName,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "firstName", $$v)
                                },
                                expression: "user.firstName"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "profile__actions" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "profile__button profile__button--save button text-capitalize",
                              attrs: {
                                height: 60,
                                disabled: _vm.isLoading,
                                type: "submit",
                                "x-large": ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.upperFirst(_vm.$t("save"))) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "profile__button button button_white text-capitalize",
                              attrs: { height: 60, "x-large": "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.cancel($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.upperFirst(_vm.$t("cancel"))) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12",
                          lg: "9",
                          offset: "0",
                          "offset-lg": "3"
                        }
                      },
                      [
                        _vm.user.customizedMaterials
                          ? _c("div", {
                              staticClass: "profile__customizes-materials",
                              domProps: {
                                innerHTML: _vm._s(_vm.user.customizedMaterials)
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-overlay",
        { attrs: { value: _vm.isLoading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { title: "action not available" },
          on: {
            onCloseModal: function($event) {
              _vm.open = false
            }
          },
          model: {
            value: _vm.open,
            callback: function($$v) {
              _vm.open = $$v
            },
            expression: "open"
          }
        },
        [
          _c("template", { slot: "content" }, [
            _c("div", { staticClass: "modal__content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("broadcasting will be available soon")) +
                  " "
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }