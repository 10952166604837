<template>
  <div class="avatar">
    <div class="avatar__top">
      <div
        class="avatar__image"
        :style="{backgroundImage: `url(${value})`}"
      />
    </div>

    <div class="avatar__bottom" @click="uploadNewPhoto">
      <div class="avatar__bottom-text">
        {{ $t('change photo') }}
      </div>

      <div class="avatar__bottom-reload">
        <c-img
          src="assets/img/svg/reload.svg"
          alt=""
        />
      </div>
    </div>

    <div class="avatar__file">
      <v-file-input
        ref="imageInput"
        type="file"
        accept="image/*"
        class="profile__image-input"
        v-bind="$attrs"
        @change="onImageChange"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Blob],
      default: null
    },
    initials: {
      type: String,
      default: null
    }
  },
  methods: {
    onImageChange (file) {
      if (!this.$refs.imageInput.validate()) return
      if (file) {
        const blob = URL.createObjectURL(file)
        this.$emit('input', blob)
        this.$emit('change', blob)
      }
    },
    uploadNewPhoto () {
      this.$refs.imageInput.$el.querySelector('[type=file]').click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles";

.avatar {
  width: 230px;
  height: 290px;
  max-width: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include below(map_get($grid-breakpoints, sm)) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 0;
  }

  .avatar__top {
    flex-grow: 1;
    z-index: 1;

    @include below(map_get($grid-breakpoints, sm)) {
      flex-grow: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @include below(map_get($grid-breakpoints, sm)) {
      width: 100px;
      height: 100px;
      border-radius: 15px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 20px;
    background: rgba(#F4F4F4, 1);
    cursor: pointer;
    margin-top: -15px;
    padding-top: 28px;
    padding-bottom: 13px;

    @include below(map_get($grid-breakpoints, sm)) {
      flex: 1;
      border-radius: 15px;
      margin-top: 0;
      padding-top: 13px;
      margin-left: -15px;
      padding-left: 35px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      z-index: 0;
    }

    &-reload {
      margin-left: auto;
    }
  }

  &__file {
    display: none;
  }
}
</style>
