import Vuex from 'vuex'
import userService, { defaultState } from '@/services/user'

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  actions: {
    async getUser ({ commit }) {
      const user = await userService.getUser(true)
      commit('updateUser', user)

      return user
    },

    async updateUser ({ commit }, payload) {
      const user = await userService.updateUser(payload)
      commit('updateUser', user)

      return user
    }
  },

  mutations: {
    updateUser (state, payload) {
      state.user = {
        ...state.user,
        ...payload
      }
    }
  }
})
