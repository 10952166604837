var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatar" }, [
    _c("div", { staticClass: "avatar__top" }, [
      _c("div", {
        staticClass: "avatar__image",
        style: { backgroundImage: "url(" + _vm.value + ")" }
      })
    ]),
    _c(
      "div",
      { staticClass: "avatar__bottom", on: { click: _vm.uploadNewPhoto } },
      [
        _c("div", { staticClass: "avatar__bottom-text" }, [
          _vm._v(" " + _vm._s(_vm.$t("change photo")) + " ")
        ]),
        _c(
          "div",
          { staticClass: "avatar__bottom-reload" },
          [
            _c("c-img", {
              attrs: { src: "assets/img/svg/reload.svg", alt: "" }
            })
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "avatar__file" },
      [
        _c(
          "v-file-input",
          _vm._b(
            {
              ref: "imageInput",
              staticClass: "profile__image-input",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.onImageChange }
            },
            "v-file-input",
            _vm.$attrs,
            false
          )
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }